<template>
  <CForm
    v-if="isReady"
    ref="updateTag"
    v-on:submit.prevent="updateSettings(true)"
    novalidate
    class="update-tag-form"
  >
    <CreateHeader
      :loading="loading"
      :descriptions="descriptions"
      :title="texts.editPage.title"
      submit-title="update"
    />
    <FormBuilder
      :list="formList"
      :isCreateHeader="false"
      @updated="updateFormData"
      :page="{
          title: texts.editPage.title,
          info: descriptions,
          mode: 'update',
        }"
    />
  </CForm>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from "@/config";
import { webhooks } from '@/config/descriptions/webhooks';
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';
import { webhooksTexts } from '@/config/pageTexts/webhooks.json';
import webhookFields from '@/generated/ziqni/store/modules/webhooks/fields';
import CreateHeader from "@/shared/components/CreateHeader";

export default {
  name: 'EditWebhook',
  components: {
    CreateHeader,
  },
  data() {
   return {
     model: 'webhook',
     entityId: this.$route.params.id,
     entityName: '',
     descriptions: {
       ...webhooks.edit
     },
     texts: {
       ...webhooksTexts
     },
     isShowDropdown: false,
     tabName: "settings",
     tabClasses: "btn edit-tab",
     selectLabel: formConfig.selectLabel,
     tagPlaceholder: formConfig.tagPlaceholder,
     showCMetaInputs: false,
     showCAdjustmentFactorsInputs: false,
     formData: null,
     accountType: [],
     options: ["Slots", "Casino", "Hockey"],
     idValidate: null,
     nameValidate: null,
     typeValidate: null,
     page: pageConfig.page,
     formList: [],
     translatableFields: [],
     translationsData: {},
     modelFields : {
       ...webhookFields,
     },
     schedulingData: null,
     dependantOnData: null,
     rewardModels: ['achievement'],
     ruleModels: ['achievement'],
     settingsCollapsed: true,
     dependantOnCollapsed: false,
     schedulingCollapsed: false,
     isReady: false,
     isSettingsDataSaved: true,
     isRulesDataSaved: true,
     isRewardsDataSaved: true,
     isTranslationsDataSaved: true,
     notSavedText: 'Data not saved!',
     notSavedRulesData: {},
     notSavedTranslationsData: {},
     notSavedRewardsData: {},
   }
  },
  computed: {
    ...mapGetters('webhooks', ['success', 'message', 'loading', 'webhooks', 'webhook']),
    ...mapGetters('actionTypes', ['success', 'message', 'actionType', 'actionTypes']),
    ...mapGetters('theme', ['theme']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    created() {
      return dateFormate(this.formData.created)
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('webhooks', [
      'handleGetWebhooks_item',
      'handleUpdateWebhooks'
    ]),
    ...mapActions('actionTypes', ['handleGetActionTypes_item', 'handleGetActionTypes']),
    initialize() {
      this.handleGetActionTypes([]);
      this.handleGetWebhooks_item([this.$route.params.id],1,0)
        .then(data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name
          });

          let actionTypesIds = []
          if (data[0].actionTypeAdjustmentFactors) {
            actionTypesIds = data[0].actionTypeAdjustmentFactors.map((item) => {
              return item.actionTypeId
            })
          }

          this.query = actionTypesIds.length ? {"id": actionTypesIds.join()} : []; // just query for the ids!

          this.handleGetActionTypes_item(actionTypesIds);
          this.formList = fieldHelpers.prepareEditFormList(
            webhookFields,
            this.texts.editPage,
            this.descriptions,
            this.webhook
          );

          if (Object.keys(data[0]).includes('scheduling')) {
            this.schedulingData = cloneDeep(data[0].scheduling);
            if (this.schedulingData.startDate) {
              this.schedulingData.startDate = new Date(this.schedulingData.startDate).toISOString();
            }
            if (this.schedulingData.endDate) {
              this.schedulingData.endDate = new Date(this.schedulingData.endDate).toISOString();
            }
          }

          if (Object.keys(data[0]).includes('dependantOn')) {
            if (data[0].dependantOn) {
              this.dependantOnData = cloneDeep(data[0].dependantOn);
            } else {
              this.dependantOnData = {
                dependantOn: {
                  must: [],
                  mustNot: [],
                  should: [],
                  shouldMatchAtLeast: 1,
                },
                entityType: 'Webhook',
              }
            }
          }

          this.isReady = true;
        });
      this.translatableFields = webhookFields.baseFields.translatableFields;
    },
    showTab(name) {
      this.tabName = name;
    },
    setRulesDataSaved(val) {
      this.isRulesDataSaved = val;
    },
    setRewardsDataSaved(val) {
      this.isRewardsDataSaved = val;
    },
    setTranslationsDataSaved(val) {
      this.isTranslationsDataSaved = val;
    },
    setNotSavedRulesData(obj) {
      this.notSavedRulesData = obj;
    },
    setNotSavedTranslationsData(obj) {
      this.notSavedTranslationsData = obj;
    },
    setNotSavedRewardsData(obj) {
      this.notSavedRewardsData = obj;
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    updateDependantOnData(val) {
      if (!isEqual(this.dependantOnData.dependantOn, val.formData.dependantOn)) {
        let selectedIds = [];
        val.selectedData.forEach(item => selectedIds.push(item.id));
        this.dependantOnData.dependantOn = cloneDeep(val.formData.dependantOn);
        this.dependantOnData.dependantOn.must = this.dependantOnData.dependantOn.must.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.mustNot = this.dependantOnData.dependantOn.mustNot.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.should = this.dependantOnData.dependantOn.should.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.entityType = val.formData.entityType;
        this.isSettingsDataSaved = false;
      }
    },
    updateSchedulingData(val) {
      if (!isEqual(this.schedulingData, val)) {
        this.schedulingData = val;
        this.isSettingsDataSaved = false;
      }
    },
    updateSettings() {
      delete this.formData.statusCode;
      delete this.formData.status;
      delete this.formData.achievementLiveStatus;
      delete this.formData.spaceName;
      delete this.formData.created;
      delete this.formData.productRefId;
      delete this.formData.rewardType;
      delete this.formData.constraints;
      delete this.formData.memberRefId;
      delete this.formData.key;

      this.handleUpdateWebhooks({updateWebhookRequest: this.formData})
        .then(data => {
          this.isSettingsDataSaved = true;
          if (data.length) {
            this.$router.push({
              name: 'PreviewWebhook',
              params: {
                id: data[0].id,
              }
            })
          }
        })
    }
  },
  watch: {
    webhook() {
      this.entityName = this.webhook.name
    },
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.update-entity {
  height: 100%;
  .card-header {
    background-color: #f3f3f3;
    & ~ .row:first-of-type {
      margin-top: 1rem;
    }
  }
  .card {
    &.zq--wizard-card {
      background-color: var(--zq-sub-bg);
    }
  }
  .edit-tooltip-wrapper {
    display: inline-block;
    position: relative;
    .tooltip-old {
      position: absolute;
      visibility: hidden;
      top: -4px;
      left: 16px;
    }
  }
  .multiselect {
    &--disabled {
      .multiselect__single {
        background: none;
      }
    }
  }
  .fa-question-circle-o:hover ~ .tooltip-old {
    visibility: visible !important;
  }
  .message {
    color: var(--zq-warn);
  }
  .notSaved {
    color: var(--zq-warn) !important;
  }
  .form-content {
    border-top: 0;
  }
  .edit-page__header {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--zq-main-bg);
  }
  .wrapper_edit_forms {
    margin-top: 20px;
    display: flex;
  }
  .edit-tab {
    width: 50%;
    height: 35px;
    cursor: pointer !important;
    border-radius: 12px 12px 0 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    text-align: left;
    &.active {
      border-bottom: solid 2px #007dfb;
    }
    &.btn {
      padding: 0.375rem 1rem;
      text-align: center;
    }
  }
  .tabs_wrapper {
    height: 100%;
    max-height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: 12px 12px 0 0;
  }
  & > .form-content {border-top: none}
  .update-entity-form {
    height: calc(100% - 50px);
    input::placeholder {
      font-style: italic;
    }
    .card {
      background-color: var(--zq-sub-bg);
    }
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      .form-control.is-valid {
        padding-right: 15px;
      }
      .custom-multi-select .multiselect__select {
        opacity: 0;
      }
      .adjustment-factors-data {
        .fa-question-circle-o {
          top: 10px;
          right: -20px;
        }
      }
      .search-icon {
        position: absolute;
        width: 30px !important;
        height: 47px !important;
        right: 15px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
      }
      .editor {
        min-height: 200px;
        height: 200px;
      }
      .fa-question-circle-o {
        position: absolute;
        top: 0;
        right: -10px;
      }
      .meta-data {
        min-width: 70px;
        .fa-question-circle-o {
          top: 10px;
        }
      }
    }
  }
  @include media-breakpoint-up('768px') {
    .edit-page__header {
      padding: 0;
    }
    .wrapper_edit_forms {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: -8px;
    }
    .tabs_wrapper {
      margin: 0 3rem;
      min-width: 375px;
      width: 47%;
      max-height: 54px;
    }
    .edit-tab {
      height: 54px;
      line-height: 42px;
      cursor: pointer !important;
      &:hover {
        color: #858585;
      }
    }
  }
}
</style>
